import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const AdaptiveImage = ({ portraitImage, landscapeImage, alt, ...props }) => {
  const sources = portraitImage.photo
    ? [
        portraitImage.photo.fluid,
        {
          ...landscapeImage.photo.fluid,
          media: `(min-width: 992px)`,
        },
      ]
    : [
        portraitImage.fluid,
        {
          ...landscapeImage.fluid,
          media: `(min-width: 992px)`,
        },
      ]
  return (
    <Img
      {...props}
      fluid={sources}
      objectFit="cover"
      objectPosition="50% 50%"
      alt={alt}
    />
  )
}

export default AdaptiveImage

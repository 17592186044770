/* eslint-disable react/display-name */
import React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import useSify from "../hooks/useSify"
import Link from "./link"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { usePageContext } from "@ivor/gatsby-theme-intl"
import Youtube from "./youtube"
import Vimeo from "./vimeo"
import { Container } from "react-bootstrap"
import usePunctuation from "../hooks/usePunctuation"

const Text = ({ children }) => <p className="rich-text-node">{children}</p>

const CNOptions = {
  renderMark: {
    [MARKS.BOLD]: text => (
      <strong>
        {typeof text == "array" || text instanceof Array
          ? text.map(t => useSify(t))
          : useSify(text)}
      </strong>
    ),
    [MARKS.ITALIC]: text => (
      <em>
        {typeof text == "array" || text instanceof Array
          ? text.map(t => useSify(t))
          : useSify(text)}
      </em>
    ),
    [MARKS.TEXT]: text => (
      <>
        {typeof text == "array" || text instanceof Array
          ? text.map(t => useSify(t))
          : useSify(text)}
      </>
    ),
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node, next) => <div>{next(node.content)}</div>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri} data-test="test">
        {children}
      </Link>
    ),

    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <li className="">
          {children.map(child =>
            (typeof child == "string" || child instanceof String) > 0
              ? useSify(node.content[0].content[0].value)
              : node.content[0].content[0].value
          )}
        </li>
      )
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <ul className="list-unstyled">
          {children.map(child =>
            (typeof child == "string" || child instanceof String) > 0
              ? useSify(child)
              : child
          )}
        </ul>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].nodeType != "text") {
        return
      }
      return (
        <Text>
          {children.map(child =>
            (typeof child == "string" || child instanceof String) > 0
              ? useSify(child)
              : child
          )}
        </Text>
      )
    },

    [BLOCKS.HEADING_1]: (node, children) => (
      <h1>
        {children.map(child =>
          (typeof child == "string" || child instanceof String) > 0
            ? useSify(child)
            : child
        )}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2>
        {children.map(child =>
          (typeof child == "string" || child instanceof String) > 0
            ? useSify(child)
            : child
        )}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3>
        {children.map(child =>
          (typeof child == "string" || child instanceof String) > 0
            ? useSify(child)
            : child
        )}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4>
        {children.map(child =>
          (typeof child == "string" || child instanceof String) > 0
            ? useSify(child)
            : child
        )}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5>
        {children.map(child =>
          (typeof child == "string" || child instanceof String) > 0
            ? useSify(child)
            : child
        )}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6>
        {children.map(child =>
          (typeof child == "string" || child instanceof String) > 0
            ? useSify(child)
            : child
        )}
      </h6>
    ),
  },
}

const RichText = ({ noFollow, body, ...props }) => {
  const { qlang } = usePageContext()
  const { comma, stop } = usePunctuation()
  const {
    allContentfulTaggedPhoto,
    allContentfulAsset,
  } = useStaticQuery(graphql`
    query photoData {
      allContentfulTaggedPhoto {
        edges {
          node {
            caption
            title
            node_locale
            photo {
              fluid(maxWidth: 1600, quality: 50) {
                src
                srcSet
                aspectRatio
              }
            }
            tags {
              name
            }
            contentful_id
          }
        }
      }
      allContentfulAsset {
        edges {
          node {
            description
            title
            node_locale
            fluid(maxWidth: 1600, quality: 50) {
              src
              srcSet
              aspectRatio
            }

            contentful_id
          }
        }
      }
    }
  `)

  let opts = CNOptions

  opts.renderNode[INLINES.HYPERLINK] = (node, children) => {
    if (
      node.data.uri.includes("youtu.be") ||
      node.data.uri.includes("youtube")
    ) {
      return <Youtube src={node.data.uri} />
    }
    if (node.data.uri.includes("vimeo")) {
      return <Vimeo src={node.data.uri} />
    }
    return (
      <Link to={node.data.uri} rel="nofollow">
        {children}
      </Link>
    )
  }
  opts.renderNode[BLOCKS.EMBEDDED_ASSET] = (n, children) => {
    const targetID = n.data.target.sys.contentful_id
    // const targetType = n.data.target.sys.contentType.sys.id

    const searchResult = allContentfulAsset.edges.filter(
      edge =>
        edge.node.contentful_id == targetID && edge.node.node_locale == qlang
    )
    const { node } =
      searchResult && searchResult.length ? searchResult[0] : null
    if (!node) {
      return null
    }
    return (
      <div className="inline-image">
        <Img
          key={node.id}
          fluid={node.fluid}
          className="card-top-image container p-0 m-0"
          alt={`${node.title}${comma}${node.caption}${stop}`}
        />
      </div>
    )
  }
  opts.renderNode[BLOCKS.EMBEDDED_ENTRY] = (n, children) => {
    const targetID = n.data.target.sys.contentful_id
    const targetType = n.data.target.sys.contentType.sys.id

    if (targetType == "taggedPhoto") {
      const searchResult = allContentfulTaggedPhoto.edges.filter(
        edge =>
          edge.node.contentful_id == targetID && edge.node.node_locale == qlang
      )
      if (!searchResult || !searchResult.length) {
        return <></>
      }
      const { node } =
        searchResult && searchResult.length ? searchResult[0] : null
      if (!node) return <></>

      return (
        <div className="inline-image">
          <Img
            key={node.id}
            fluid={node.photo.fluid}
            className="card-top-image  container p-0 m-0"
            alt={`${node.title}, ${node.caption}, tagged as: ${node.tags
              .map(tag => tag.name)
              .join(", ")}.`}
          />
        </div>
      )
    }
    if (targetType == "gallery") {
      // const searchResult = allContentfulTaggedPhoto.edges.filter(
      //   edge =>
      //     edge.node.contentful_id == targetID && edge.node.node_locale == qlang
      // )
      // if (!searchResult.length) {
      //   return <></>
      // }
      // const { node } = searchResult[0]
      // console.log("debug photo: ", node, targetID, allContentfulTaggedPhoto)
      // return (
      //   <Img
      //     key={node.id}
      //     fluid={node.photo.fluid}
      //     className="card-top-image"
      //     alt={`${node.title}, ${node.caption}, tagged as: ${node.tags
      //       .map(tag => tag.name)
      //       .join(", ")}.`}
      //   />
      // )
    }
    return <></>
  }
  if (body && body.json) {
    var chunk = -1
    var wasEmbed = false
    var result = body.json.content.reduce((resultArray, item, index) => {
      if (
        item.nodeType == "embedded-asset-block" ||
        item.nodeType == "embedded-entry-block"
      ) {
        resultArray.push({
          data: { ...item.data },
          content: [],
          nodeType: "document",
          fullWidth: true,
        })
        chunk += 1
        wasEmbed = true
      } else if (
        (item.nodeType == "heading-2" && index != 0) ||
        index == 0 ||
        wasEmbed
      ) {
        if (wasEmbed) wasEmbed = false
        resultArray.push({
          data: { ...item.data },
          content: [],
          nodeType: "document",
        })
        chunk += 1
      }

      resultArray[chunk]["content"].push(item)

      return resultArray
    }, [])

    return (
      <>
        {result.map((obj, i) => {
          return !obj.fullWidth && !props.noContainer ? (
            <Container
              key={i}
              id={i == 0 && props && props.id ? props.id : ""}
              fluid
              isRichText
              className="fw m-0 p-0 body-container"
            >
              <Container className="my-0 pt-1 pt-md-3 pb-3 pb-md-5">
                {documentToReactComponents(obj, opts)}
              </Container>
            </Container>
          ) : (
            <>{documentToReactComponents(obj, opts)}</>
          )
        })}
      </>
    )
  }
  return <></>
}

export default RichText

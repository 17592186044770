import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Facebook from "./facebook"
import Twitter from "./twitter"
import Icons from "./icons"
import { usePageContext } from "@ivor/gatsby-theme-intl"
import useSify from "../hooks/useSify"
// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({
  index,
  title,
  description,
  banner,
  article,
  author,
  createdAt,
  updatedAt,
  schema,
}) => {
  const { site, allContentfulMeta } = useStaticQuery(query)
  const { lang, qlang, localizedPath } = usePageContext()
  const meta = allContentfulMeta.edges.filter(
    (edge) => edge.node.node_locale == qlang
  )[0].node
  const separator = " • "

  const {
    metaKeywords,
    icon1024,
    defaultCreatedAt,
    defaultUpdatedAt,
    defaultTitle,
    siteUrl,
    address,
    defaultDescription,
    facebook,
    twitter,
    instagram,
    defaultAuthor,
    vimeo,
    defaultBanner,
  } = meta
  const bannerCap = banner ? banner.caption : ""
  const seo = {
    title: useSify(title),
    defaultTitle: useSify(defaultTitle),
    description: useSify(description),
    defaultDescription: useSify(defaultDescription),
    image: `https:${
      banner ? banner.photo.file.url : defaultBanner.photo.file.url
    }`,
    logo: `https:${icon1024.file.url || icon1024.file.url}`,

    imageAlt: useSify(bannerCap),
    defaultImageAlt: useSify(defaultBanner.caption),
    url: `${siteUrl}${localizedPath}`,
    author: useSify(author),
    defaultAuthor: useSify(defaultAuthor),
    createdAt: new Date(createdAt || defaultCreatedAt)
      .toISOString()
      .split("T")[0],
    updatedAt: site.buildTime,
    siteUrl: `${siteUrl}/${lang}/`,
    keywords: useSify(metaKeywords),
  }

  const combinedTitle = index
    ? seo.title
    : seo.title + separator + seo.defaultTitle

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": seo.siteUrl,
        name: defaultTitle,
      },
      position: 1,
    },
  ]
  if (!index) {
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: combinedTitle,
      },
      position: 2,
    })
  }
  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: seo.url,
    headline: title,
    inLanguage: lang,
    mainEntityOfPage: seo.siteUrl,
    description: seo.description || seo.defaultDescription,
    name: combinedTitle,
    author: {
      "@type": "Person",
      name: seo.author || seo.defaultAuthor,
    },
    copyrightHolder: {
      "@type": "Person",
      name: seo.author || seo.defaultAuthor,
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Person",
      name: seo.author || seo.defaultAuthor,
    },
    publisher: {
      "@type": "Person",
      name: seo.author || seo.defaultAuthor,
    },
    datePublished: seo.createdAt,
    dateModified: seo.updatedAt,
    image: {
      "@type": "ImageObject",
      url: seo.image,
    },
  }

  const schemaArticle = {
    "@context": "http://schema.org",
    "@type": "Article",
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
      logo: {
        "@type": "ImageObject",
        url: seo.logo,
      },
    },
    datePublished: seo.createdAt,
    dateModified: seo.updatedAt,
    description: seo.description || seo.defaultDescription,
    headline: combinedTitle,
    inLanguage: lang,
    url: seo.url,
    name: combinedTitle,
    image: {
      "@type": "ImageObject",
      url: seo.image,
    },
    mainEntityOfPage: seo.url,
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <>
      <Helmet title={combinedTitle}>
        <meta
          name="description"
          content={seo.description || seo.defaultDescription}
        />
        {lang.includes("zh") ? (
          <meta name="keywords" content={seo.keywords} />
        ) : (
          ""
        )}
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && !schema && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && !schema && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {schema && (
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        description={seo.description || seo.defaultDescription}
        image={seo.image}
        title={combinedTitle}
        type={article ? "article" : "website"}
        url={seo.url}
        lang={lang}
        name={defaultTitle}
        alt={seo.imageAlt || seo.defaultImageAlt}
      />
      <Twitter
        title={combinedTitle}
        image={seo.image}
        desc={seo.description || seo.defaultDescription}
        alt={seo.imageAlt || seo.defaultImageAlt}
      />
      <Icons title={seo.defaultTitle} />
    </>
  )
}
export default SEO

SEO.propTypes = {
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
}
const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
    allContentfulMeta(filter: { siteUrl: { eq: "https://liyuenjing.com" } }) {
      edges {
        node {
          metaKeywords
          defaultCreatedAt: createdAt
          defaultUpdatedAt: updatedAt
          defaultTitle: siteTitle
          siteUrl
          address
          defaultDescription: description
          facebook
          twitter
          instagram
          defaultAuthor: author
          vimeo
          defaultBanner: banner {
            photo {
              file {
                url
              }
            }
            caption
          }
          icon1024 {
            file {
              url
            }
          }
          node_locale
        }
      }
    }
  }
`

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { usePageContext } from "@ivor/gatsby-theme-intl"
import useSify from "../hooks/useSify"

const Icons = ({ title }) => {
  const { allContentfulMeta } = useStaticQuery(query)
  const { qlang } = usePageContext()
  const meta = allContentfulMeta.edges.filter(
    (edge) => edge.node.node_locale == qlang
  )[0].node

  const {
    favicon,
    favicon16,
    favicon32,
    favicon48,
    icon57,
    icon60,
    icon72,
    icon76,
    icon114,
    icon120,
    icon144,
    icon152,
    icon167,
    icon180,
    icon1024,
  } = meta

  return (
    <Helmet>
      <link rel="shortcut icon" href={favicon.file.url} />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={favicon16.file.url}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={favicon32.file.url}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href={favicon48.file.url}
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon57.file.url}
        sizes="57x57"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon60.file.url}
        sizes="60x60"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon72.file.url}
        sizes="72x72"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon76.file.url}
        sizes="76x76"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon114.file.url}
        sizes="114x114"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon120.file.url}
        sizes="120x120"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon144.file.url}
        sizes="144x144"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon152.file.url}
        sizes="152x152"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon167.file.url}
        sizes="167x167"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon180.file.url}
        sizes="180x180"
      />
      <link
        rel="apple-touch-icon"
        href={"https:" + icon1024.file.url}
        sizes="1024x1024"
      />

      <meta
        name="msapplication-TileImage"
        content={"https:" + icon114.file.url}
      />
      <meta name="msapplication-TileColor" content="#f8f9fa" />
      <meta name="application-name" content={useSify(icon57.title)} />
      <meta
        name="msapplication-square70x70logo"
        content={"https:" + icon72.file.url}
      />
      <meta
        name="msapplication-square150x150logo"
        content={"https:" + icon152.file.url}
      />
      <meta name="apple-mobile-web-app-capable" content="yes"></meta>
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      ></meta>
      <meta
        name="apple-mobile-web-app-title"
        content={useSify(icon57.title)}
      ></meta>
    </Helmet>
  )
}

export default Icons

const query = graphql`
  query LOGOS {
    allContentfulMeta(filter: { siteUrl: { eq: "https://liyuenjing.com" } }) {
      edges {
        node {
          favicon {
            file {
              url
            }
          }
          favicon16 {
            file {
              url
            }
          }
          favicon32 {
            file {
              url
            }
          }
          favicon48 {
            file {
              url
            }
          }
          icon57 {
            title
            file {
              url
            }
          }
          icon60 {
            file {
              url
            }
          }
          icon72 {
            file {
              url
            }
          }
          icon76 {
            file {
              url
            }
          }
          icon114 {
            file {
              url
            }
          }
          icon120 {
            file {
              url
            }
          }
          icon144 {
            file {
              url
            }
          }
          icon152 {
            file {
              url
            }
          }
          icon167 {
            file {
              url
            }
          }
          icon180 {
            file {
              url
            }
          }
          icon1024 {
            file {
              url
            }
          }

          node_locale
        }
      }
    }
  }
`

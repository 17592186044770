import { sify } from "chinese-conv"
import { usePageContext } from "@ivor/gatsby-theme-intl"

export const s = (str, lang) => {
  return lang.includes("cn") || lang.includes("Hans") ? sify(String(str)) : str
}

const useSify = str => {
  const { lang } = usePageContext()
  return lang.includes("cn") || lang.includes("Hans") ? sify(String(str)) : str
}

export default useSify

import React from "react"
import useSify from "../hooks/useSify"
import { usePageContext } from "@ivor/gatsby-theme-intl"

const Youtube = ({ src }) => {
  const { qlang } = usePageContext()
  const uid = src
    .replace("https://www.youtube.com/watch?v=", "")
    .replace("https://youtu.be/", "")

  return (
    <div className={`video my-3 full-width video-responsive`}>
      <iframe
        title={`${useSify("none")}`}
        src={`https://www.youtube-nocookie.com/embed/${uid}`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      {/* <iframe
        src={props.vimeo}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen &cc_lang_pref=fr&cc_load_policy=1
      ></iframe> */}
    </div>
  )
}
export default Youtube

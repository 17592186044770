import React, { useState, useRef, useMemo, Fragment, useEffect } from "react"
import { usePageContext, Link } from "@ivor/gatsby-theme-intl"
import LanguageSwitcher from "./languageSwitcher"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useStaticQuery, graphql } from "gatsby"
import useTranslatedString from "../hooks/useTranslatedString"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { s } from "../hooks/useSify"
import Scrollspy from "react-scrollspy"
const getOffset = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth) >=
    992
    ? 100
    : Math.max(document.documentElement.clientWidth, window.innerWidth) >= 768
    ? 30
    : 10
}

const NavBar = () => {
  const {
    qlang,
    lang,
    localizedPath,
    defaultLanguage,
    originalPath,
  } = usePageContext()
  const [hideOnScroll, setHideOnScroll] = useState(false)
  const [navbarCollapsed, setNavbarCollapsed] = useState(true)
  const inputRef = useRef(null)

  const wrapperRef = useRef(null)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!navbarCollapsed) {
          inputRef.current.click()
          setNavbarCollapsed(true)
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef, navbarCollapsed])

  const {
    allContentfulPage,
    allContentfulShow,
    allContentfulMeta,
  } = useStaticQuery(graphql`
    query navBar {
      allContentfulMeta(filter: { siteUrl: { eq: "https://liyuenjing.com" } }) {
        edges {
          node {
            siteTitle
            node_locale
          }
        }
      }
      allContentfulPage {
        edges {
          node {
            slug
            title
            node_locale
          }
        }
      }
      allContentfulShow {
        edges {
          node {
            title
            slug
            node_locale
            isMainstage
            eventTimes {
              startTime
            }
          }
        }
      }
    }
  `)
  const { siteTitle } = allContentfulMeta.edges.filter(
    edge => edge.node.node_locale == qlang
  )[0].node
  const startPosition = -30
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        !hideOnScroll &&
        currPos.y < prevPos.y &&
        prevPos.y <= 0 &&
        currPos.y <= startPosition
      ) {
        if (!navbarCollapsed) {
          inputRef.current.click()
          setNavbarCollapsed(true)
        }
        setHideOnScroll(true)
      } else if (currPos.y > startPosition || currPos.y > prevPos.y) {
        setHideOnScroll(false)
      }
    },
    [hideOnScroll, navbarCollapsed],
    false,
    false,
    200
  )
  return (
    <>
      <Navbar
        ref={wrapperRef}
        fixed="top"
        collapseOnSelect
        className={`p-0 w-100 ${hideOnScroll ? "hide" : ""}`}
        expand="md"
        bg="dark"
        variant="dark"
      >
        {originalPath == "/" ? (
          <AnchorLink
            href="#top"
            className="navbar-brand pl-4 mr-4"
            offset={() => getOffset()}
          >
            {"Mili"}
          </AnchorLink>
        ) : (
          <Link to={"/"} className="navbar-brand pl-4">
            {"Mili"}
          </Link>
        )}

        <Navbar.Collapse id="responsive-navbar-nav" className="h-100">
          <Nav className="mr-auto my-0">
            <Scrollspy
              items={["about", "shows", "teaching", "contact"]}
              currentClassName="is-current h-100"
              className="scroll-spy"
              offset={-120}
            >
              <AnchorLink
                href="#about"
                className="nav-link m-0 p-3 h-100"
                offset={() => getOffset()}
              >
                {useTranslatedString("about")}
              </AnchorLink>
              <AnchorLink
                href="#shows"
                className="nav-link my-0 p-3"
                offset={() => getOffset()}
              >
                {useTranslatedString("shows")}
              </AnchorLink>
              <AnchorLink
                href="#teaching"
                className="nav-link my-0 p-3"
                offset={() => getOffset()}
              >
                {useTranslatedString("teaching")}
              </AnchorLink>
              <AnchorLink
                href="#contact"
                className="nav-link my-0 p-3"
                offset={() => getOffset()}
              >
                {useTranslatedString("contact-me")}
              </AnchorLink>
            </Scrollspy>
          </Nav>
        </Navbar.Collapse>
        <Nav className="language-switcher ml-auto">
          <LanguageSwitcher />
        </Nav>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="mr-1 my-1"
          ref={inputRef}
          onClick={() => setNavbarCollapsed(!navbarCollapsed)}
        />
      </Navbar>
    </>
  )
}

export default NavBar

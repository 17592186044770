/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Link from "./link"

import {
  Container,
  Jumbotron as BootstrapJumbotron,
  Row,
  Col,
} from "react-bootstrap"

import AdaptiveImage from "./adaptiveImage"
import useSify, { s } from "../hooks/useSify"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { usePageContext } from "@ivor/gatsby-theme-intl"
import useTranslatedString from "../hooks/useTranslatedString"
import useLanguageClass from "../hooks/useLanguageClass"
import usePunctuation from "../hooks/usePunctuation"
import Time from "./time"
import Img from "gatsby-image"
const CardHeader = ({
  title,
  subtitle,
  author,
  date,
  originalPublication,
  originalUrl,
  supersub,
  portraitImage,
}) => {
  //TODO: test offset at larger dimensions

  const { comma, enumcomma } = usePunctuation()
  const { qlang, lang } = usePageContext()

  const sTitle = useSify(title)
  const sSubtitle = useSify(subtitle)

  return (
    <>
      <Container fluid className="m-0 p-0 pt-nav-short body-container bg-light">
        <Container className="my-0 pt-1 pt-md-3 pb-0">
          <Row className="p-0 mt-3">
            <Col className="col-12 col-sm-6">
              {
                <h1
                  className={`mt-0 display-4 ${qlang == "zh" ? "chinese" : ""}`}
                >
                  {sTitle}
                </h1>
              }
              {supersub ? <h5>{s(supersub, lang)}</h5> : null}
              {subtitle ? (
                <h4 className="text-reset my-1 text-lowercase">{sSubtitle}</h4>
              ) : null}
              {/* <p className="d-none d-sm-block">
                <span class="badge badge-pill badge-secondary">
                  {"ivor@rooftopproductions.hk"}
                </span>
              </p> */}
            </Col>
            {portraitImage ? (
              <Col className="col-12 col-sm-6 mt-3 mt-sm-0 mb-0">
                <Img
                  fluid={portraitImage.fluid}
                  className="headshot-card mx-auto mr-sm-1 mb-0"
                  alt={`${portraitImage.title}, ${portraitImage.description}`}
                />
              </Col>
            ) : null}
          </Row>
        </Container>
      </Container>
    </>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default CardHeader

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { Container } from "react-bootstrap"

const Body = ({ children, ...props }) => {
  let childArray

  if (!children || !children.length) return null
  if (children.length == 1 && children[0].length > 1) {
    childArray = [...children[0]]
  } else {
    childArray = [...children]
  }

  return (
    <>
      {childArray.map((child, i) => {
        if (!child) return
        if (
          child.props &&
          (child.props.isRichText || child.props.uncontained)
        ) {
          return child
        }
        if (child.props && child.props.isFullWidth) {
          return (
            <Container
              key={`body-${i}`}
              id={i == 0 && props && props.id ? props.id : ""}
              fluid
              className="m-0 p-0 body-container"
            >
              {child}
            </Container>
          )
        }
        return (
          <Container
            id={i == 0 && props && props.id ? props.id : ""}
            key={`body-${i}`}
            fluid
            className="m-0 p-0 body-container"
          >
            <Container className="my-0 pt-1 pt-md-3 pb-3 pb-md-5">
              {child}
            </Container>
          </Container>
        )
      })}
    </>
  )
}

export default Body

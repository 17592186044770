/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import NavBar from "./navbar"
import Footer from "./footer"
// import { usePageContext } from "@ivor/gatsby-theme-intl"
// import "../fonts/fonts.css"

import "../style/style.scss"
import useLanguageClass from "../hooks/useLanguageClass"

const Layout = ({ children }) => {
  return (
    <div className={useLanguageClass()} id="top">
      <NavBar />

      <main>{children}</main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

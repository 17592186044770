import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { usePageContext, Link } from "@ivor/gatsby-theme-intl"

const LanguageSwitcher = () => {
  const {
    supportedLanguages,
    defaultLanguage,
    originalPath,
    lang,
    langUrl,
  } = usePageContext()
  const labels = {
    en: "EN",
    "zh-Hant": "繁",
    "zh-Hans": "简",
  }
  const langUrlDict = {
    "zh-Hant": "hk",
    "zh-Hans": "cn",
    "en-US": "en",
    "en-GB": "en",
    en: "en",
    zh: "zh",
  }
  return (
    <>
      {supportedLanguages
        .filter(supportedLang => supportedLang != lang)
        .map((supportedLang, i) => (
          <GatsbyLink
            state={{ preventScroll: true }}
            aria-label={`Change language to ${supportedLang}`}
            key={supportedLang}
            to={`/${langUrlDict[supportedLang]}${originalPath}`}
            className={`${supportedLang.toLowerCase()}-content nav-link`}
            style={supportedLang == "en" ? { fontWeight: "400" } : {}}
          >
            {labels[supportedLang]}
          </GatsbyLink>
        ))}
    </>
  )
}

export default LanguageSwitcher

import React from "react"
import moment from "moment/min/moment-with-locales"
import { usePageContext } from "@ivor/gatsby-theme-intl"
// Sets the moment instance to use.

// Set the locale for every react-moment instance to French.
// Moment.globalLocale = "fr"

// // Set the output format for every react-moment instance.
// Moment.globalFormat = "D MMM YYYY"

// // Set the timezone for every instance.
// // Moment.globalTimezone = "Asia/Hong_Kong"

// // Set the output timezone for local for every instance.
// Moment.globalLocal = true

// // Use a <span> tag for every react-moment instance.
// Moment.globalElement = "span"

// // Upper case all rendered dates.
// OriginalMoment.globalFilter = d => {
//   return d.toUpperCase()
// }
const locales = {
  "zh-Hant": "zh-hk",
  "zh-Hans": "zh-cn",
  en: "en-gb",
}

export const getTime = (input, duration) => {
  const inputDate = new Date(input)

  const convertedTime = duration
    ? moment(inputDate)
        .utcOffset("+0800")
        .add(duration, "m")
    : moment(inputDate).utcOffset("+0800")

  return convertedTime
}

export const getMoment = (input, lang) => {
  const locale = locales[lang]
  moment.locale(locale)
  return moment(input).utcOffset("+0800")
}

export const getMonthYear = (input, lang) => {
  const locale = locales[lang]
  moment.locale(locale)
  const inputDate = new Date(input)
  const convertedTime = moment(inputDate).utcOffset("+0800")
  return lang == "en" ? (
    <span>{convertedTime.format("MMMM YYYY")}</span>
  ) : (
    <span>{convertedTime.format("MMMYYYY")}</span>
  )
}

const Time = ({ children, time }) => {
  const { lang } = usePageContext()
  const locale = locales[lang]
  moment.locale(locale)
  const inputDate = new Date(children)
  const convertedTime = moment(inputDate).utcOffset("+0800")
  const date = time ? convertedTime.format("LLLL") : convertedTime.format("LL")
  return <span data-time={+inputDate}>{date}</span>
}

export default Time

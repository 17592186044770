import React from "react"
import useSify from "../hooks/useSify"

const Vimeo = ({ src, title }) => {
  return (
    <div className={`video my-3 full-width video-responsive`}>
      <iframe
        title={`${useSify(title || "Vimeo")}`}
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  )
}
export default Vimeo

import React from "react"
import { Link as InternalLink } from "@ivor/gatsby-theme-intl"

import { OutboundLink } from "gatsby-plugin-google-analytics"

// Taken from: https://www.gatsbyjs.org/docs/gatsby-link/
// If given link begins with a single `/`, treat as internal Gatsby Link
const Link = ({
  doFollow,
  noFollow,
  children,
  to,
  activeClassName,
  ...other
}) => {
  const nfinternal = /^nf\//g.test(to)
  const internal = /^\//g.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal || nfinternal) {
    return (
      <InternalLink
        to={!nfinternal ? to : to.replace(/^nf\//g, "/")}
        rel={noFollow || nfinternal ? "nofollow" : ""}
        activeClassName={activeClassName}
        {...other}
      >
        {children}
      </InternalLink>
    )
  }
  return (
    <OutboundLink
      href={to}
      rel={doFollow ? "" : "nofollow"}
      target="_blank"
      {...other}
    >
      {children}
    </OutboundLink>
  )
}

export default Link

import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

const Facebook = ({
  url,
  name,
  type,
  title,
  description,
  image,
  lang,
  alt,
}) => (
  <Helmet>
    <meta property="og:site_name" content={name} />
    <meta property="og:locale" content={lang} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={alt} />
    <meta property="fb:admins" content="773174632761539" />
    <meta property="fb:app_id" content="164674214001370" />
  </Helmet>
)

export default Facebook

Facebook.propTypes = {}

Facebook.defaultProps = {
  type: "website",
  name: "Rooftop Productions",
}

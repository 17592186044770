import { sify } from "chinese-conv"
import { useStaticQuery, graphql } from "gatsby"
import { usePageContext } from "@ivor/gatsby-theme-intl"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const useTranslatedString = str => {
  const { lang, qlang } = usePageContext()

  const data = useStaticQuery(graphql`
    query translationStrings {
      allContentfulString {
        edges {
          node {
            slug
            string
            node_locale
          }
        }
      }
    }
  `)

  const find = data.allContentfulString.edges.filter(
    edge => edge.node.slug === str && edge.node.node_locale === qlang
  )
  const output =
    find.length && find[0].node && find[0].node.string
      ? find[0].node.string
      : "error: " + str + " for " + lang
  return lang.includes("cn") || lang.includes("Hans") ? sify(output) : output
}

export default useTranslatedString

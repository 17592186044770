import { usePageContext } from "@ivor/gatsby-theme-intl"
import React from "react"
import { s } from "./useSify"

const useLanguageClass = () => {
  const { lang, qlang } = usePageContext()

  return {
    measureNominations: qlang == "zh" ? s("項", lang) : " ",
    comma: qlang == "zh" ? "，" : ", ",
    stop: qlang == "zh" ? "。" : ". ",
    colon: qlang == "zh" ? "：" : ": ",
    semicolon: qlang == "zh" ? "；" : "; ",
    enumcomma: qlang == "zh" ? "、" : ", ",
    question: qlang == "zh" ? "？" : "? ",
    lbracket: qlang == "zh" ? "（" : " (",
    rbracket: qlang == "zh" ? "）" : ") ",
    exclamation: qlang == "zh" ? "！" : "! ",
    plural: qlang == "zh" ? "" : "s",
    space: qlang == "zh" ? "" : " ",
  }
}

export default useLanguageClass

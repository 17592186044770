import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import AnchorLink from "react-anchor-link-smooth-scroll"
import RichText from "../components/richtext"
import {
  Container,
  CardColumns,
  Card,
  CardGroup,
  CardDeck,
  Row,
  Col,
} from "react-bootstrap"
import Jumbotron from "../components/jumbotron"
import About from "../fragments/about"
import SEO from "../seo/seo"
import useTranslatedString from "../hooks/useTranslatedString"
import { s } from "../hooks/useSify"
import Map from "../components/map"
import Body from "../components/body"
import LazyMap from "../components/lazyMap"
import { usePageContext } from "@ivor/gatsby-theme-intl"
import Gallery from "../components/gallery"
import moment from "moment"
import usePunctuation from "../hooks/usePunctuation"
import { getMonthYear } from "../components/time"
import SingleImage from "../components/singleImage"
import Link from "../components/link"
import AdaptiveImage from "../components/adaptiveImage"
import CardHeader from "../components/cardHeader"
import ContactForm from "../components/contactForm"
import { FaTrophy } from "react-icons/fa/"
const getOffset = () => {
  return -100
  return Math.max(document.documentElement.clientWidth, window.innerWidth) >=
    992
    ? 100
    : Math.max(document.documentElement.clientWidth, window.innerWidth) >= 768
    ? 30
    : 10
}
function isOdd(num) {
  return num % 2
}
const IndexPage = ({ pageContext, data }) => {
  const { lang, qlang } = usePageContext()
  const aboutUs = s(useTranslatedString("about-us"), lang)
  const sLatestShow = s(useTranslatedString("latest-show"), lang)
  const sShows = s(useTranslatedString("shows"), lang)
  // const shows = useTranslatedString("shows")
  const sAwards = s(useTranslatedString("awards"), lang)
  const sWon = s(useTranslatedString("won"), lang)
  const sNominated = s(useTranslatedString("nominated"), lang)
  const sNominations = s(useTranslatedString("nominations"), lang)
  const sReviews = s(useTranslatedString("reviews"), lang)
  const sArtisticDirectors = s(useTranslatedString("artistic-directors"), lang)

  const {
    space,
    plural,
    comma,
    stop,
    rbracket,
    lbracket,
    enumcomma,
    colon,
    measureNominations,
  } = usePunctuation()

  const awards = data.allContentfulAward.edges.map(edge => {
    const {
      slug,
      category,
      awardBody,
      won,
      showNominated,
      peopleNominated,
    } = edge.node
    return (
      <>
        <span>{`${showNominated.title} was nominated for ${category} by ${awardBody.name}`}</span>
      </>
    )
  })
  const langUrlDict = {
    "zh-Hant": "hk",
    "zh-Hans": "cn",
    "en-US": "en",
    "en-GB": "en",
    en: "en",
    zh: "zh",
  }
  const shows = data.allContentfulShow.edges
    .filter(edge => edge.node.eventTimes || edge.node.roughDate)
    .sort((a, b) => {
      if (a.node.eventTimes && b.node.eventTimes) {
        return (
          new Date(a.node.eventTimes[0].startTime) -
          new Date(b.node.eventTimes[0].startTime)
        )
      } else if (a.node.eventTimes && b.node.roughDate) {
        return (
          new Date(a.node.eventTimes[0].startTime) - new Date(b.node.roughDate)
        )
      } else if (a.node.roughDate && b.node.eventTimes) {
        return (
          new Date(a.node.roughDate) - new Date(b.node.eventTimes[0].startTime)
        )
      } else {
        return new Date(a.node.roughDate) - new Date(b.node.roughDate)
      }
    })
    .reverse()
    .map((edge, i) => {
      const {
        title,
        venueName,
        showRoles,
        metaDescription,
        bannerPortrait,
        bannerLandscape,
        eventTimes,
        roughDate,
        duration,
        award,
        slug,
        category,
        externalUrl,
      } = edge.node
      const sortedRoles = showRoles
        ? showRoles
            .filter(
              sr =>
                sr.people.filter(p => p.slug == "michelle-li").length &&
                sr.role.priority < 23
            )
            .sort((a, b) => a.role.priority - b.role.priority)
            .map((showRole, i) => showRole.role.roleName)
        : []
      return (
        <Link
          key={"li" + i}
          className={"wrapper-link"}
          doFollow={category.slug.includes("rooftop") ? true : false}
          to={
            externalUrl
              ? externalUrl
              : category.slug.includes("rooftop")
              ? `https://rooftopproductions.hk/${langUrlDict[lang]}/${slug}`
              : `/${slug}`
          }
        >
          <Row
            as="dl"
            className={"cursor-pointer mb-0 py-4 text-white position-relative"}
            key={"show" + i}
          >
            {bannerLandscape ? (
              <AdaptiveImage
                className="show-img-behind"
                landscapeImage={bannerLandscape}
                portraitImage={bannerLandscape || bannerPortrait}
                alt={bannerLandscape.description}
              />
            ) : (
              <div className="show-img-behind"></div>
            )}
            <dt class="col-sm-6 text-center my-auto text-sm-right font-weight-bold">
              <Link
                className="display-5 mt-0 text-white"
                doFollow={category.slug.includes("rooftop") ? true : false}
                to={
                  externalUrl
                    ? externalUrl
                    : category.slug.includes("rooftop")
                    ? `https://rooftopproductions.hk/${langUrlDict[lang]}/${slug}`
                    : `/${slug}`
                }
              >
                {s(title, lang)}
              </Link>
              <p
                className={`mt-1 mb-2 text-white ${
                  qlang == "zh" ? "mr-3" : ""
                }`}
              >
                {s(category.company, lang)}
              </p>
            </dt>
            <dd class="col-sm-6 mb-0 my-auto text-center text-sm-left">
              <h5 className="my-1 text-white">{sortedRoles.join(enumcomma)}</h5>

              <p className="mt-1 mb-0">
                <small className="text-white">
                  {getMonthYear(
                    eventTimes ? eventTimes[0].startTime : roughDate,
                    lang
                  )}
                  {" | "}
                  {s(venueName, lang)}
                </small>
              </p>

              <p className="mt-2 mb-1">
                {award
                  ? award
                      .filter(a => a.won)
                      .map((a, i) => (
                        <>
                          {" "}
                          <span class="badge badge-pill badge-light pt-1">
                            <FaTrophy key={"trophy" + i} />{" "}
                            {s(a.category, lang)}
                          </span>{" "}
                        </>
                      ))
                  : null}
                {award ? (
                  <>
                    {" "}
                    <span class="badge badge-pill badge-dark pt-1">
                      <FaTrophy />{" "}
                      {award.length + measureNominations + sNominations}
                    </span>
                  </>
                ) : null}
              </p>
            </dd>
          </Row>
        </Link>
      )
    })
  const ACard = ({ props }) => {
    const {
      title,
      category,
      slug,
      externalUrl,
      school,
      eventTimes,
      roughDate,
      shortDescription,
      venueName,
    } = props
    return (
      <Card key={"li" + slug} className={"m-0 my-3 my-md-2 mx-md-2"}>
        <div class="card-body">
          <h4 class="card-title">
            <Link
              className={""}
              doFollow={category.slug.includes("rooftop") ? true : false}
              to={
                externalUrl
                  ? externalUrl
                  : category.slug.includes("rooftop")
                  ? `https://rooftopproductions.hk/${langUrlDict[lang]}/${slug}`
                  : `/${slug}`
              }
            >
              {s(title, lang)}
            </Link>
          </h4>
          <h6 class="card-subtitle mb-2 text-muted">
            {s(category.company, lang)}
          </h6>

          <p class="card-text">{shortDescription}</p>
        </div>
        <div className="card-footer">
          <small>
            {getMonthYear(
              eventTimes
                ? eventTimes[0].startTime
                : roughDate
                ? roughDate
                : new Date(),
              lang
            )}
            {school ? (
              <>
                {" | "}
                <Link className={"wrapper-link card-link"} to={school.url}>
                  {s(school.name, lang)}
                </Link>
              </>
            ) : null}
            {venueName ? " | " + s(venueName, lang) : null}
          </small>
        </div>
      </Card>
    )
  }
  const sortedTeaching = data.allContentfulTeaching.edges
    .filter(edge => edge.node.eventTimes || edge.node.roughDate)
    .sort((a, b) => {
      if (a.node.eventTimes && b.node.eventTimes) {
        return (
          new Date(a.node.eventTimes[0].startTime) -
          new Date(b.node.eventTimes[0].startTime)
        )
      } else if (a.node.eventTimes && b.node.roughDate) {
        return (
          new Date(a.node.eventTimes[0].startTime) - new Date(b.node.roughDate)
        )
      } else if (a.node.roughDate && b.node.eventTimes) {
        return (
          new Date(a.node.roughDate) - new Date(b.node.eventTimes[0].startTime)
        )
      } else {
        return new Date(a.node.roughDate) - new Date(b.node.roughDate)
      }
    })
    .reverse()
    .map((t, i, arr) => {
      // const { title, slug, category, externalUrl } = t.node

      if (isOdd(i)) {
        return
      }

      return (
        <>
          <CardGroup>
            <ACard props={t.node} />

            {arr[i + 1] ? <ACard props={arr[i + 1].node} /> : null}
          </CardGroup>
        </>
      )
    })

  const sortedReviews = data.allContentfulReview.edges
    .map(edge => edge.node)
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .reverse()
    .map(r => (
      <li className="list-group-item" key={r.slug}>
        {`${s(r.authors, lang)}${colon}`}
        <Link to={`/${r.slug}`}>{s(r.title, lang)}</Link>
        {comma}
        {s(r.publication, lang)}
        {comma}
        {getMonthYear(r.date, lang)}
      </li>
    ))

  const sortedAwards = data.allContentfulAward.edges
    .map(edge => edge.node)
    .filter(award => award.won)
    .sort(
      (a, b) =>
        new Date(a.showNominated.eventTimes[0].startTime) -
        new Date(b.showNominated.eventTimes[0].startTime)
    )
    .reverse()
    .map((award, i, arr) => (
      <li
        className={`list-group-item text-center list-group-item-${
          award.won ? "primary" : "secondary"
        }`}
        key={i + award.showNominated.title}
      >
        {
          <>
            <Link to={`/${award.showNominated.slug}`}>
              {s(award.showNominated.title, lang)}
            </Link>
            <span>{`${lbracket + s(award.category, lang) + rbracket}`}</span>
            <Link to={award.link}>
              {s(award.awardBody.name, lang) +
                comma +
                moment(award.showNominated.eventTimes[0].startTime).format(
                  "YYYY"
                )}
            </Link>
          </>
        }
      </li>
    ))
  const sortedNominations = data.allContentfulAward.edges
    .map(edge => edge.node)
    .filter(award => !award.won)
    .sort(
      (a, b) =>
        new Date(a.showNominated.eventTimes[0].startTime) -
        new Date(b.showNominated.eventTimes[0].startTime)
    )
    .reverse()
    .map((award, i, arr) => (
      <span key={i + award.showNominated.title}>
        {`${s(award.showNominated.title, lang)}` +
          lbracket +
          s(award.category, lang) +
          `${
            award.peopleNominated
              ? colon +
                award.peopleNominated.map(p => s(p.name, lang)).join(enumcomma)
              : ""
          }` +
          rbracket +
          s(award.awardBody.name, lang) +
          comma +
          moment(award.showNominated.eventTimes[0].startTime).format("YYYY") +
          `${i < arr.length - 1 ? enumcomma : stop}`}
      </span>
    ))

  // const ivorAndMichelle = peopleShowcase.map(p => (
  //   <Card key={p.slug}>
  //     {p.landscapeImage && p.portraitImage ? (
  //       <Link to={`/${p.slug}`}>
  //         <AdaptiveImage
  //           className="card-img-top cursor-pointer"
  //           landscapeImage={p.portraitImage}
  //           portraitImage={p.landscapeImage}
  //           alt={s(p.portraitImage.description, lang)}
  //         />
  //       </Link>
  //     ) : null}

  //     <div className="card-body">
  //       <h5 className="card-title">
  //         <Link to={`/${p.slug}`}>{s(p.name, lang)}</Link>
  //       </h5>
  //       <p className="card-text">{p.metaDescription.metaDescription}</p>
  //       <p className="card-text">
  //         <small className="text-muted">{s(p.job, lang)}</small>
  //       </p>
  //     </div>
  //   </Card>
  // ))
  // .sort((a, b) => (a.won === b.won ? 0 : a.won ? -1 : 1))
  // .map(theAward => (
  //   <li
  //     className={`list-group-item text-center list-group-item-${
  //       theAward.won ? "info" : "secondary"
  //     }`}
  //     key={theAward.id}
  //   >
  //     {theAward.awardBody.name} {theAward.category}{" "}
  //     {theAward.peopleNominated
  //       ? lbracket +
  //         theAward.peopleNominated
  //           .map(person => person.name)
  //           .join(enumcomma) +
  //         rbracket
  //       : ""}
  //     {theAward.won
  //       ? lbracket + sWon + rbracket
  //       : lbracket + sNominated + rbracket}
  //   </li>
  // ))
  const { site, contentfulMeta, contentfulTag } = data

  const separator = " â€¢ "

  const {
    metaKeywords,
    icon1024,
    defaultCreatedAt,
    defaultUpdatedAt,
    defaultTitle,
    address,
    defaultDescription,
    siteUrl,
    facebook,
    twitter,
    instagram,
    linkedIn,
    youtube,
    vimeo,

    createdAt,
    updatedAt,
    defaultBanner,
  } = contentfulMeta

  const {
    name,
    familyName,
    givenName,
    tagLine,
    job,
    portraitImage,
    landscapeImage,
    childContentfulTagDescriptionRichTextNode,
    childContentfulTagOtherWorkRichTextNode,
    childContentfulTagMetaDescriptionTextNode,
    nationality,
  } = contentfulTag

  const schema = {
    "@context": "http://schema.org",
    "@type": "Person",
    url: siteUrl,
    name: s(name, lang),

    jobTitle: s(job, lang),
    nationality: s(nationality, lang),
    image: `https:${defaultBanner ? defaultBanner.photo.file.url : ""}`,
    logo: `https:${icon1024.file.url}`,
    location: {
      "@type": "PostalAddress",
      addressLocality: lang == "en" ? "Hong Kong" : s("香港", lang),
      streetAddress: s(address, lang),
    },
    email: "ivor@rooftopproductions.hk",
    worksFor: {
      "@type": "Organization",
      name: "Rooftop",
    },
    award: data.allContentfulAward.edges
      .map(edge => edge.node)
      .filter(award => award.won)
      .map(
        (award, i, arr) =>
          `${s(award.showNominated.title, lang)}` +
          lbracket +
          s(award.category, lang) +
          `${
            award.peopleNominated
              ? colon +
                award.peopleNominated.map(p => s(p.name, lang)).join(enumcomma)
              : ""
          }` +
          rbracket +
          s(award.awardBody.name, lang) +
          comma +
          moment(award.showNominated.eventTimes[0].startTime).format("YYYY") +
          `${i < arr.length - 1 ? enumcomma : stop}`
      ),

    knowsLanguage: [
      {
        "@type": "Language",
        identifier: "yue-HK",
      },
      {
        "@type": "Language",
        identifier: "en",
      },
      {
        "@type": "Language",
        identifier: "zh",
      },
    ],

    sameAs: [instagram, twitter, linkedIn, youtube, facebook],
  }

  return (
    <Layout>
      <SEO
        index={true}
        schema={schema}
        title={defaultTitle}
        description={childContentfulTagMetaDescriptionTextNode.metaDescription}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <CardHeader
        portraitImage={portraitImage}
        title={name}
        subtitle={tagLine}
      />

      <Container id="about" fluid className="m-0 p-0 body-container bg-light">
        <Container className="my-0 pt-1 pt-md-3 pb-3 pb-md-5">
          <RichText
            noContainer={true}
            className="m-0 p-0"
            body={childContentfulTagDescriptionRichTextNode}
          />
          {/* <RichText
            noContainer={true}
            className="m-0 p-0"
            body={childContentfulTagOtherWorkRichTextNode}
          /> */}
        </Container>
      </Container>

      <Container
        fluid
        id="shows"
        className="striped-container m-0 p-0 body-container"
      >
        {/* <h2 className="text-white">{sShows}</h2> */}

        {shows}
      </Container>
      <Container fluid className="m-0 p-0 body-container">
        <Container
          id="teaching"
          className="my-0 px-0 px-sm-2 pt-1 pt-md-3 pb-3 pb-md-5"
        >
          <h2>{useTranslatedString("teaching")}</h2>

          {sortedTeaching}
          {/* <p className="small text-muted pt-3">
            {sNominations + colon}
            {sortedNominations}
          </p> */}
        </Container>
      </Container>
      <Container fluid className="m-0 p-0 body-container bg-light">
        <Container id="contact" className="my-0 pt-1 pt-md-3 pb-3 pb-md-5">
          <h2>{useTranslatedString("contact-me", lang)}</h2>

          <ContactForm />

          {/* <p className="small text-muted pt-3">
            {sNominations + colon}
            {sortedNominations}
          </p> */}
        </Container>
      </Container>
      {/* <Body>
      <Container id="reviews" fluid className="m-0 p-0 body-container">
        <Container className="my-0 pt-1 pt-md-3 pb-3 pb-md-5">
          <h2>{sReviews}</h2>

          <ul className="list-unstyled">{sortedReviews}</ul>
        </Container>
      </Container>
      {/* <Body>
        {awards}
        <div id="tickets">
          <h2>Tickets</h2>
         <RichText
            noContainer
            body={childContentfulShowAudienceNotesRichTextNode}
          />
          <ul>{sortedEventTimes}</ul> 
        </div>*/}
      {/* <LazyMap
          uncontained
          props={{
            language: lang,
            lat: venue.lat,
            lon: venue.lon,
          }}
        /> */}
      {/* {lang == "en" ? (
          <EngMap props={{ lat: venue.lat, lon: venue.lon }} />
        ) : (
          <Map props={{ lat: venue.lat, lon: venue.lon }} />
        )} */}
      {/* <div id="team">
          <h2>Team</h2>
          <ul className="list-group list-group-flush">
            {sortedRoles}
            <li className="list-group-item">
              {useTranslatedString("acknowledgement") + plural + colon}
              {acknowledgements.map(n => n.name).join(enumcomma)}
              {stop}
            </li>
          </ul>
        </div> */}

      {/* <RichText
          id={"directors-notes"}
          body={childContentfulShowDirectorsNotesRichTextNode}
        /> 
      </Body>*/}
      {/* {tagged_photo.length ? (
        <Gallery
          id="gallery"
          // key="12234"
          // slug="testimony"
          taggedImages={tagged_photo}
          // title="testimony"
          itemsPerRow={[2, 3, 4]}
          // parent="testimony"
        />
      ) : (
        <div>No photos available</div>
      )} */}
    </Layout>
  )
}

export const query = graphql`
  query IndexPage($qlang: String) {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
    contentfulTag(slug: { eq: "michelle-li" }, node_locale: { eq: $qlang }) {
      nationality
      name
      node_locale
      tagLine
      job
      childContentfulTagDescriptionRichTextNode {
        json
      }
      childContentfulTagOtherWorkRichTextNode {
        json
      }
      childContentfulTagMetaDescriptionTextNode {
        metaDescription
      }
      portraitImage {
        file {
          url
        }
        description
        fluid(maxWidth: 320, maxHeight: 320) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      landscapeImage {
        file {
          url
        }

        fluid(maxWidth: 560, quality: 50) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }

    contentfulMeta(
      node_locale: { eq: $qlang }
      siteUrl: { eq: "https://liyuenjing.com" }
    ) {
      createdAt
      updatedAt
      metaKeywords
      defaultCreatedAt: createdAt
      defaultUpdatedAt: updatedAt
      defaultTitle: siteTitle
      siteUrl
      address
      defaultDescription: description
      facebook
      twitter
      instagram
      defaultAuthor: author
      vimeo
      youtube
      defaultBanner: banner {
        photo {
          file {
            url
          }
        }
        caption
      }

      icon1024 {
        file {
          url
        }
      }
      siteTitle
    }

    allContentfulReview(filter: { node_locale: { eq: $qlang } }) {
      edges {
        node {
          title
          authors
          publication
          slug
          date
          createdAt
        }
      }
    }
    allContentfulAward(filter: { node_locale: { eq: $qlang } }) {
      edges {
        node {
          link
          slug
          category
          awardBody {
            name
          }
          won
          showNominated {
            title
            slug
            eventTimes {
              startTime
            }
          }
          peopleNominated {
            name
          }
        }
      }
    }
    allContentfulShow(
      filter: {
        node_locale: { eq: $qlang }
        showRoles: {
          elemMatch: { people: { elemMatch: { slug: { eq: "michelle-li" } } } }
        }
      }
    ) {
      edges {
        node {
          slug
          bannerLandscape {
            file {
              url
            }
            description
            fluid(maxWidth: 560, quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          bannerPortrait {
            file {
              url
            }
            description
            fluid(maxWidth: 560, quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          isMainstage
          title
          metaDescription
          eventTimes {
            startTime
          }
          venueName
          roughDate
          duration
          externalUrl
          category {
            slug
            company
          }
          showRoles {
            role {
              roleName
              priority
            }
            people {
              name
              slug
            }
          }
          award {
            category
            won
            slug
          }
        }
      }
    }
    allContentfulTeaching(
      filter: {
        node_locale: { eq: $qlang }
        mainTeachers: { elemMatch: { slug: { eq: "michelle-li" } } }
      }
    ) {
      edges {
        node {
          slug
          externalUrl
          title
          school {
            slug
            name
            url
          }

          venueName
          shortDescription
          roughDate
          roughEndDate

          category {
            slug
            company
          }
        }
      }
    }
  }
`

export default IndexPage

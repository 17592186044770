import React, { useState } from "react"
import { navigate } from "gatsby-link"
import { useGoogleReCaptcha, GoogleReCaptcha } from "react-google-recaptcha-v3"
import { usePageContext, Link } from "@ivor/gatsby-theme-intl"
import { Form, Button, Col, Spinner } from "react-bootstrap"
import { validate, string, util, isValid } from "valid.js"
import RecapWarning from "../components/recapWarning"
import useTranslatedString from "../hooks/useTranslatedString"
import { s } from "../hooks/useSify"
import usePunctuation from "../hooks/usePunctuation"
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const getWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth)
}

const ContactForm = ({ props }) => {
  const { lang, localizedPath } = usePageContext()
  const GOOGLE_APP =
    "https://script.google.com/macros/s/AKfycbxXePUNAywYBEFn0i9kqOywQ30QSPh9hdnxFeejSrhEBgL9XNA3/exec"
  const SHEET_NAME = "Michelle"

  const [formValues, setFormValues] = useState({})
  const [formProgress, setFormProgress] = useState(0)

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    })
  }

  const [captcha, setCaptcha] = useState(false)

  const handleToken = value => {
    console.log("thecaptcha ", value)
    setCaptcha(value)
  }

  const [validated, setValidated] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    console.log(captcha)
    if (!captcha) return
    const form = e.target
    if (!form.checkValidity()) return
    setValidated(true)
    setFormProgress(1)

    // recaptchaRef.current.execute()
    const proxyurl = "https://rooftop-proxy.herokuapp.com/"
    const url = GOOGLE_APP // site that doesn�t send Access-Control-*
    fetch(proxyurl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sheet: SHEET_NAME,
        language: lang,
        url: localizedPath,
        ...formValues,
      }),
    }) // https://cors-anywhere.herokuapp.com/https://example.com
      .then(response => {
        // console.log("res ", response)
        if (response.status === 200) setFormProgress(3)
        else setFormProgress(2)
      })
      .catch(
        () => setFormProgress(2)
        //blocked by browser? Proxy down? Ivor messed up?
      )
  }
  const { colon, comma, enumcomam } = usePunctuation()
  const sSubmit = s(useTranslatedString("submit"), lang)
  const sName = s(useTranslatedString("name"), lang)
  const sYourName = s(useTranslatedString("your-name"), lang)
  const sEmail = s(useTranslatedString("email"), lang)
  const sMessage = s(useTranslatedString("message"), lang)
  const sYourMessage = s(
    useTranslatedString("what-would-you-like-to-say-to-me"),
    lang
  )
  const sSomethingWentWrong = s(
    useTranslatedString("something-went-wrong"),
    lang
  )
  const sThanks = s(
    useTranslatedString("thank-you-for-submission-first-person"),
    lang
  )

  return (
    <>
      {formProgress < 2 ? (
        <Form
          validated={validated}
          name="contact-form"
          method="post"
          // action={`/${langUrl}/thanks`}
          onSubmit={handleSubmit}
        >
          <Form.Row>
            <Col className="col-12 col-md-6">
              <Form.Group controlId="name" className=" mr-md-2">
                <Form.Label>{sName}</Form.Label>
                <Form.Control
                  disabled={formProgress === 1}
                  type="text"
                  name="name"
                  placeholder={sYourName}
                  required
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col className="col-12 col-md-6">
              <Form.Group controlId="email" className=" ml-md-2">
                <Form.Label>{sEmail}</Form.Label>
                <Form.Control
                  disabled={formProgress === 1}
                  type="email"
                  name="email"
                  placeholder="your@email.com"
                  required
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group controlId="body">
                <Form.Label>{sMessage}</Form.Label>
                <Form.Control
                  disabled={formProgress === 1}
                  type="text"
                  name="body"
                  placeholder={sYourMessage}
                  required
                  onChange={handleChange}
                  as="textarea"
                  rows="6"
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <GoogleReCaptcha onVerify={token => handleToken(token)} />
          <Form.Row className="py-3">
            <Col className="col-12 ">
              <Form.Group className="text-center text-md-left">
                <Button
                  size="lg"
                  className="btn-block-reactive "
                  variant="primary"
                  type="submit"
                  disabled={formProgress === 1}
                >
                  {formProgress === 1 ? (
                    <Spinner
                      animation="border"
                      variant="bright"
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    sSubmit
                  )}
                </Button>
              </Form.Group>
              <RecapWarning center={true} />
            </Col>
          </Form.Row>
        </Form>
      ) : formProgress < 3 ? (
        <>
          <h3>{sSomethingWentWrong}</h3>
        </>
      ) : (
        <>
          <h3>{sThanks}</h3>
          <p>
            {sName}
            {colon}
            {formValues.name}
          </p>
          <p>
            {sEmail}
            {colon}
            {formValues.email}
          </p>
          <p>
            {sMessage}
            {colon}
            {formValues.body}
          </p>
        </>
      )}
    </>
  )
}

export default ContactForm

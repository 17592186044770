import { useStaticQuery, graphql } from "gatsby"
import Link from "./link"
import PropTypes from "prop-types"
import React from "react"
import { usePageContext } from "@ivor/gatsby-theme-intl"
import { Container, Row, Col } from "react-bootstrap"
import useSify, { s } from "../hooks/useSify"
import {
  FaYoutube,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa/"
import SignUp from "../components/signUp"
import useTranslatedString from "../hooks/useTranslatedString"
import usePunctuation from "../hooks/usePunctuation"

const Footer = () => {
  const {
    site,
    allContentfulMeta,
    allContentfulShow,
    allContentfulPost,
  } = useStaticQuery(query)
  const { lang, qlang, localizedPath } = usePageContext()
  const meta = allContentfulMeta.edges.filter(
    edge => edge.node.node_locale == qlang
  )[0].node
  const {
    siteTitle,
    address,
    facebook,
    twitter,
    instagram,
    linkedIn,
    youtube,
  } = meta
  const separator = " • "
  const sifyAddress = useSify(address)
  const rooftop = s(useTranslatedString("rooftop-productions"), lang)
  const showAddress = address ? separator + `${sifyAddress}` : ""
  const { space } = usePunctuation()
  //TODO: add social icons, contact?
  return (
    <>
      <footer className="bg-dark">
        <Container
          className="py-3 pt-4 text-center social-media"
          variant={"dark"}
        >
          {facebook ? (
            <Link
              itemprop="sameAs"
              doFollow={true}
              className={"mr-md-5 mr-4"}
              to={facebook}
            >
              <FaFacebook style={{ fontSize: "2em" }} />
            </Link>
          ) : null}
          {instagram ? (
            <Link
              itemprop="sameAs"
              doFollow={true}
              className={""}
              to={instagram}
            >
              <FaInstagram style={{ fontSize: "2em" }} />
            </Link>
          ) : null}
          {linkedIn ? (
            <Link
              itemprop="sameAs"
              doFollow={true}
              className={" "}
              to={linkedIn}
            >
              <FaLinkedin style={{ fontSize: "2em" }} />
            </Link>
          ) : null}
          {twitter ? (
            <Link
              itemprop="sameAs"
              doFollow={true}
              className={"mr-md-5 mr-4 "}
              to={twitter}
            >
              <FaTwitter style={{ fontSize: "2em" }} />
            </Link>
          ) : null}
          {youtube ? (
            <Link
              itemprop="sameAs"
              doFollow={true}
              className={"  "}
              to={youtube}
            >
              <FaYoutube style={{ fontSize: "2em" }} />
            </Link>
          ) : null}
        </Container>

        <Container className="py-3 pb-4 text-center" variant={"dark"}>
          <small className="text-white">
            {`© `}
            {
              <Link
                itemprop="sameAs"
                doFollow={true}
                className={""}
                to={"https://rooftopproductions.hk"}
              >
                {rooftop}
              </Link>
            }
            {`${space}${new Date().getFullYear()}` + showAddress}
          </small>
        </Container>
      </footer>
    </>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer

const query = graphql`
  query Footer {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
    allContentfulShow {
      edges {
        node {
          title
          slug
          node_locale
          isMainstage
          eventTimes {
            startTime
          }
        }
      }
    }
    allContentfulPost {
      edges {
        node {
          title
          createdAt
          isArticle
          originalDate
          slug
          node_locale
        }
      }
    }
    allContentfulMeta(filter: { siteUrl: { eq: "https://liyuenjing.com" } }) {
      edges {
        node {
          siteTitle
          siteUrl
          youtube

          facebook
          twitter
          instagram
          linkedIn

          node_locale
        }
      }
    }
  }
`

import React from "react"
import Link from "./link"
import useSify from "../hooks/useSify"
import { usePageContext } from "@ivor/gatsby-theme-intl"

const RecapWarning = () => {
  const { lang } = usePageContext()
  const chiString = (
    <p className="small font-smaller py-0 mb-0 text-center text-md-left pt-1">
      <small>
        {useSify("本網站受reCAPTCHA 保護，Google")}
        <Link to="https://policies.google.com/privacy">
          {useSify("私隱權政策")}
        </Link>
        {useSify("和")}
        <Link to="https://policies.google.com/terms">
          {useSify("條款適用")}
        </Link>
        {"。"}
      </small>
    </p>
  )
  const engString = (
    <p className="small font-smaller py-0 mb-0 text-center text-md-left pt-1">
      <small>
        {"This site is protected by reCAPTCHA and the Google "}
        <Link to="https://policies.google.com/privacy">Privacy Policy</Link>
        {" and "}
        <Link to="https://policies.google.com/terms">
          Terms of Service
        </Link>{" "}
        apply.
      </small>
    </p>
  )

  return <>{lang.includes("zh") ? chiString : engString}</>
}

export default RecapWarning
